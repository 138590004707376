import i18next from 'i18next';
import React from 'react';
import { SET_CONTENT_ACTION } from '../../SspProvider/store';
import { LanguageMapProps } from './interfaces';
import dispatchLogEvent from '../../ActivityLogger';
import { getSetup } from '../../Functions/getSetup';

export default function LanguageMap(props: LanguageMapProps) {
	const setup = getSetup();

	return (
		<>
			{props.languages.map(language => {
				let name = i18next.exists('ThisLanguage', {
					lng: language,
				})
					? i18next.t('ThisLanguage', {
							lng: language,
						})
					: language;
				if (
					props.languages.find(
						l =>
							language.split('-')[0] === l.split('-')[0] &&
							language.split('-')[1] !== l.split('-')[1]
					) !== undefined
				) {
					name += ` (${language.split('-')[1]})`;
				}

				return (
					<div
						key={`lang-switch-${language}`}
						className={`${
							i18next.language === language ? 'active ' : ''
						}language`}
						onClick={() => {
							if (language !== i18next.language) {
								dispatchLogEvent({
									ActionName: 'Language Switch',
									ElementType: 'Language',
									ElementName: i18next.t('Language'),
								});
								document.documentElement.setAttribute(
									'lang',
									language
								);
								i18next.changeLanguage(language);
								if (
									props.store.state.ssp &&
									props.store.state.ssp.projectModel.asModel
										.contentTreeChildren.length > 0
								) {
									props.store.state.ssp.setCurrentLanguage(
										language
									);
									props.store.dispatch({
										type: SET_CONTENT_ACTION,
										value: props.store.state.ssp
											.projectModel.asModel
											.contentTreeChildren[0],
									});
								}
								if (props.setLanguageOpen) {
									props.setLanguageOpen(false);
								}
							}
						}}
					>
						{setup.brand === 'bentley' ? name.toUpperCase() : name}
					</div>
				);
			})}
		</>
	);
}
