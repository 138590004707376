import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles.scss';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { NavEnum, OverlayEnum, SspProviderContext } from '../../did-utility';
import Overview from '../ScrollableArea/Overview';
import Equipment from '../ScrollableArea/Equipment';
import TechData from '../ScrollableArea/TechData';
import PriceInfo from '../PriceInfo';
import HeightLimitCarousel from '../../did-utility/src/Components/Carousel/HeightLimit';
import NotifyScrollable from '../../did-utility/src/Components/NotifyScrollable';
import Overlay from '../Overlay';
import Footer from '../../did-utility/src/Components/Footer';
import Header from './header';
import HeaderContent from '../../did-utility/src/Components/HeaderContent';
import { hasHeaderContent } from '../../did-utility/src/Components/HeaderContent/hasHeaderContent';
import {
	SET_NAV_TAB_ACTION,
	SET_OVERLAYS_ACTION,
	SET_QR_ACTION,
} from '../../did-utility/src/SspProvider/store';

export default function MainPage() {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const headerRef = useRef<HTMLDivElement>(null);
	const [isBottom, setIsBottom] = useState(false);

	window.addEventListener('reset', () => {
		store.dispatch({
			type: SET_OVERLAYS_ACTION,
			value: [],
			noLog: true,
		});
		store.dispatch({
			type: SET_QR_ACTION,
			value: null,
		});
		store.dispatch({
			type: SET_NAV_TAB_ACTION,
			value: NavEnum.Overview,
			noLog: true,
		});
	});

	const headerContent = hasHeaderContent(contentModel) ? (
		<HeaderContent />
	) : null;
	useEffect(() => {
		const handleScroll = () => {
			const scrollableElement = document.getElementById('main-page');
			if (scrollableElement) {
				const bottomDistance =
					scrollableElement.scrollHeight -
					scrollableElement.scrollTop -
					scrollableElement.clientHeight;
				setIsBottom(bottomDistance <= 48);
			}
		};

		const scrollableElement = document.getElementById('main-page');
		if (scrollableElement) {
			scrollableElement.addEventListener('scroll', handleScroll);
			return () =>
				scrollableElement.removeEventListener('scroll', handleScroll);
		} else {
			console.log('Scrollable element not found');
		}
	}, []);

	return (
		<NotifyScrollable
			className='main-page'
			id='main-page'
			dontShow={store.state.overlays.length > 0}
			onScroll={() => {
				if (headerContent !== null) {
					const topDistance =
						headerRef.current?.getBoundingClientRect().top || 0;
					if (topDistance < 0) {
						headerRef.current?.classList.add('sticking');
					} else {
						headerRef.current?.classList.remove('sticking');
					}
				}
			}}
		>
			{headerContent && (
				<div className='header-content'>{headerContent}</div>
			)}
			<Header
				headerContent={headerContent}
				contentModel={contentModel}
				store={store}
				headerRef={headerRef}
			/>
			<PriceInfo hidden={store.state.navTab !== NavEnum.Overview} />
			<HeightLimitCarousel
				currentIndex={store.state.navTab}
				setCurrentIndex={(index: number) =>
					store.dispatch({
						type: SET_NAV_TAB_ACTION,
						value: index,
					})
				}
				margin={58} // Carousel margin
				looping={true}
				keyName='main-page-carousel'
			>
				<Overview />
				<Equipment />
				<TechData />
			</HeightLimitCarousel>
			<Footer showLargeFooter={isBottom} brand='audi' />
			<div
				className={`menu-flyout${
					store.state.overlays.includes(OverlayEnum.Menu)
						? ' open '
						: ''
				}`}
			>
				<Overlay overlay={OverlayEnum.Menu} />
			</div>
		</NotifyScrollable>
	);
}
