import React from 'react';
import './styles.scss';
import BackToSetup from '../BackToSetup';
import { getSetup } from '../../Functions/getSetup';
import logo from '../../../../../public/logo.png';
import i18next from 'i18next';

export default function Loading(props: { text?: string }): JSX.Element {
	const setup = getSetup();
	const orientationFromParams = new URLSearchParams(
		window.location.search
	).get('orientation');
	if (orientationFromParams)
		localStorage.setItem('orientation', orientationFromParams);
	const orientation =
		orientationFromParams || localStorage.getItem('orientation') || '';
	return (
		<div
			className={`loading-page ${
				setup.brand === 'bentley' ? 'bentley' : ''
			}`}
			id='#loading-page'
		>
			<div className={`wrapper ${orientation}`}>
				{setup.brand === 'bentley' ? (
					<div>
						<img src={logo} />

						{/*todo add logo for vw and audi*/}

						<p className='loading'>
							{i18next.t('ApplicationLoading')}
						</p>
					</div>
				) : (
					<div>
						<div className='loading-spinner' />
						{props.text && (
							<p className='caching-progress'>{props.text}</p>
						)}
					</div>
				)}
				{setup && setup.brand && <BackToSetup brand={setup.brand} />}
			</div>
		</div>
	);
}
