import React, { useContext, useEffect, useState } from 'react';
import { SspProviderContext } from '../../../index';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import './styles.scss';
import { canvasFromPDF } from './canvasFromPDF';
import i18next from 'i18next';
import BackToSetup from '../BackToSetup';
import Loading from '../Loading';
import { getSetup } from '../../Functions/getSetup';

var checkSWTimer: number | undefined;
export default function Label(props: { brand: string; apk: boolean }) {
    const setup = getSetup();
    const orientationFromParams = new URLSearchParams(
        window.location.search
    ).get('orientation');
    if (orientationFromParams)
        localStorage.setItem('orientation', orientationFromParams);
    const orientation =
        orientationFromParams || localStorage.getItem('orientation') || '';
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content
        ? store.state.content.asModel
        : new SSPContentVO('').asModel;
    const asset = contentModel.ref('second_display');
    const type = asset.asAsset.fileProperties.fileType;
    const deviceType = new URLSearchParams(window.location.search).get(
        'device-type'
    );

    const [swReady, setSWReady] = useState<boolean>(false);

    useEffect(() => {
        if (type === 'pdf') {
            canvasFromPDF(asset.asAsset.url);
        }
    });
    // repeatedly check if sw is registered
    function checkSW() {
        if (navigator.serviceWorker.controller) {
            setSWReady(true);
        } else {
            if (checkSWTimer) window.clearTimeout(checkSWTimer);
            checkSWTimer = window.setTimeout(
                () => checkSW(),
                1000 // 1s
            );
        }
    }

    // avoid loading asset in apk second screen without a service worker to provide the authorization!
    if (
        props.apk &&
        !swReady &&
        (type === 'pdf' ||
            type === 'jpg' ||
            type === 'png' ||
            type === 'svg') &&
        'serviceWorker' in navigator
    ) {
        checkSW();

        return <Loading text={i18next.t('WaitingForPrimaryScreen')} />;
    }

    if (type === 'jpg' || type === 'png' || type === 'svg') {
        return (
            <div className={`label ${orientation} ${type}`}>
                <img
                    src={asset.asAsset.url}
                    alt={asset.asModel.str('description')}
                />
                <BackToSetup brand={props.brand} />
            </div>
        );
    }

    if (type === 'pdf') {
        return (
            <div
                className={`label ${orientation} ${
                    setup.market.name === 'DEU' ? deviceType : ''
                }`}
            >
                <div id='canvas' />
                <BackToSetup brand={props.brand} />
            </div>
        );
    }

    return (
        <div className={`label ${orientation} label-default`}>
            <div className='wrapper'>
                <svg>
                    <use xlinkHref='#brand-icon' />
                </svg>
                <h1>Digital Info Display</h1>
            </div>
            <BackToSetup brand={props.brand} />
        </div>
    );
}
