import {
	clearUnsentEvents,
	fetchWithTimeout,
	makeGUID,
	saveUnsentEvents,
	STORAGE_KEY,
} from './util';
import { getSetup } from '../Functions/getSetup';
import LogData from './interfaces';

export const UNSENT_EVENTS_KEY = 'unsentEvents';

const setup = getSetup();
const TRACKING_URL = setup?.url || null;
const DEVICE = setup?.device || '';
const LANGUAGE = setup?.language || '';
let apiToken: string | undefined;
const params = new URLSearchParams(window.location.search);
const apk = params.get('apk') === 'true';
const webDid = !apk;
let eventQueue: LogData[] = localStorage.getItem(UNSENT_EVENTS_KEY)
	? JSON.parse(localStorage.getItem(UNSENT_EVENTS_KEY)!)
	: [];

async function sendData(data: any) {
	try {
		if (TRACKING_URL && apiToken) {
			try {
				const response = await fetchWithTimeout(
					`${TRACKING_URL}/module/stats/bpca/api/v2/events`,
					data,
					apiToken
				);

				if (response.ok) {
					clearUnsentEvents(); // Clear persisted events on success
					eventQueue = [];
				}
			} catch (error) {
				console.error('Error while sending events:', error);
			}
		} else if (TRACKING_URL) {
			const auth = window.btoa(`ssp:${setup?.password || ''}`);
			const res = await fetch(
				`${TRACKING_URL}/module/content/bpca/depot/os/statistics`,
				{
					method: 'GET',
					headers: {
						Authorization: 'Basic ' + auth,
						'Service-Worker': 'false',
					},
				}
			);

			if (res.status === 200) {
				apiToken = (await res.text()).replace(/(\r\n|\n|\r)/gm, '');
				await sendData(data);
			} else {
				console.error('Tracking token not found!');
			}
		}
	} catch (error) {
		console.error(error);
		saveUnsentEvents(eventQueue); // Persist unsent events
	}
}

window.addEventListener('online', () => {
	console.log('Internet connection restored. Retrying to send events...');
	flushEventQueue();
});

export async function flushEventQueue() {
	if (eventQueue.length === 0) return;
	try {
		await sendData(eventQueue);
	} catch (error) {
		console.error('Failed to send data:', error);
	}
}

export default function dispatchLogEvent(data: LogData) {
	const timezoneOffset = new Date().getTimezoneOffset() * 60000;
	const localISOTime = new Date(
		(data.Timestamp || Date.now()) - timezoneOffset
	).toISOString();

	if (navigator.onLine && !window.location.href.includes('localhost')) {
		let completeData: any = {
			AdditionalData: {
				PricesheetTitle:
					sessionStorage.getItem('pricesheetTitle') || '',
				Carline: sessionStorage.getItem('carline') || '',
				CarlineName: sessionStorage.getItem('carlineName') || '',
				Salesgroup: sessionStorage.getItem('salesgroup') || '',
				SalesgroupName: sessionStorage.getItem('salesgroupName') || '',
				ModelKey: sessionStorage.getItem('modelKey') || '',
				EngineName: sessionStorage.getItem('engineName') || '',
				ModelYear: sessionStorage.getItem('modelYear') || '',
				Vin: sessionStorage.getItem('vin') || '',
				CommissionNumber:
					sessionStorage.getItem('commissionNumber') || '',
				WebDid: webDid,
				KVPS: setup.market.name + setup.kvps,
				Brand:
					setup.brand === 'audi'
						? 'Audi'
						: setup.brand === 'vw'
							? 'Volkswagen'
							: setup.brand === 'bentley'
								? 'Bentley'
								: '',
			},
			DeviceId: DEVICE,
			DeviceName: DEVICE,
			SuiteName: sessionStorage.getItem('suite') || '',
			DeviceLanguage: document.documentElement.lang || LANGUAGE,
			SessionId: sessionStorage.getItem(STORAGE_KEY) || makeGUID(),
			...data,
			Timestamp: localISOTime,
		};

		eventQueue.push(completeData);
		saveUnsentEvents(eventQueue);
	}
}
