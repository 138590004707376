import React, { useContext, useState } from 'react';
import './styles.scss';
import { addOverlay, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import {
	UpperTableElement,
	UpperTableElementNedcWltp,
	UpperTableElementNumber,
} from './upperTableElement';
import i18next from 'i18next';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import { TeaserImage } from '../../did-utility/src/Components/TeaserImage';
import Disclaimers from '../../did-utility/src/Components/Disclaimers';
import { getSetup } from '../../did-utility/src/Functions/getSetup';
import { showInfoIcon } from '../../did-utility/src/Functions/showInfoIcon';
import SSPContentFieldAccessor from 'sspcontentext/src/model/accessor/SSPContentFieldAccessor';

export default function Overview(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const highlightEquip = contentModel
		.reflist('highlight_equip')
		.filter(item => item.asModel.bool('marketable', true));
	const usedCar = store.state.content?.contentType === 'UsedPricesheet';

	const moreData = (
		<p
			className='small more-data'
			onClick={() => addOverlay(store, OverlayEnum.Consumption)}
		>
			{i18next.t('LinkAdditionalConsumptionData')}
			<svg className='icon-12'>
				<use xlinkHref='#chevron-xsmall' />
			</svg>
		</p>
	);

	const engineDisclaimers = engine.arr('disclaimers') as Array<{
		Key: string;
		Value: string;
	}>;
	const disclaimerTags: Array<string> = [];
	['consumption.fuel-type'].forEach(tag => {
		if (engineDisclaimers.findIndex(dis => dis.Key === tag) !== -1)
			disclaimerTags.push(tag);
	});
	const getDisclaimerFromTag = (tag: string) =>
		disclaimerTags.includes(tag)
			? engineDisclaimers.findIndex(d => d.Key === tag) + 1
			: undefined;

	const [showMoreOpen, setShowMoreOpen] = useState<boolean>(false);

	const setup = getSetup();
	const params = new URLSearchParams(window.location.search);
	const apk = params.get('apk') === 'true';
	const allHaveAsset = highlightEquip?.every(item =>
		item.asModel.has('assets')
	);

	// critAir in FRA
	const engineType = engine.str('engine_type');
	let critAir;

	if (engineType === 'Electric') {
		critAir = 'bev';
	} else if (['HybridPetrol', 'Petrol', 'Gas'].includes(engineType)) {
		critAir = 1;
	} else if (engineType === 'Diesel') {
		critAir = 2;
	}

	// Function to handle engine-specific details rendering
	function renderEngineSpecificDetails(
		engine: SSPContentFieldAccessor,
		contentModel: SSPContentFieldAccessor,
		vehicleDataModel: SSPContentFieldAccessor
	) {
		const engineType = engine.str('engine_type');
		if (engineType === 'Electric') {
			const hasWltpRange = contentModel
				.ref('vehicle_data')
				.asModel.has('wltp_range_combined');
			const hasNedcRange = contentModel
				.ref('vehicle_data')
				.asModel.has('nedc_range_combined');
			return hasWltpRange || hasNedcRange ? (
				<UpperTableElementNedcWltp
					headline={'OverviewBoxRangeCombined'}
					value={'xxx_range_combined'}
					unit={i18next.t('LengthUnit')}
					model={vehicleDataModel}
				/>
			) : engine.has('battery_charging_dc_100') ? (
				<UpperTableElement
					headline={'OverviewBoxChargingTime'}
					value1={engine.str('battery_charging_dc_100', '-')}
					unit1={i18next.t('ChargingTimeUnit')}
				/>
			) : (
				renderBatteryAndPerformanceDetails(engine)
			);
		} else {
			return (
				<div
					className={`${
						engineType === 'HybridPetrol'
							? 'hybrid fueltype'
							: 'fueltype'
					}`}
				>
					<UpperTableElement
						headline={'OverviewBoxFuelType'}
						value1={engine.str('fuel')}
						value3={engine.str('fuel2')}
					/>
				</div>
			);
		}
	}

	// Function to handle details specific to used cars
	function renderUsedCarDetails(contentModel: SSPContentFieldAccessor) {
		return (
			<>
				<UpperTableElement
					headline={'InitialRegistration'}
					value1={new Date(
						contentModel.obj('initialreg').Timestamp
					).toLocaleDateString(i18next.language, {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					})}
				/>
				<UpperTableElement
					headline={'MileageUsedCar'}
					h3={contentModel
						.float('mileage', NaN)
						.toLocaleString(i18next.language, {
							useGrouping: true,
						})}
					unit1={i18next.t('LengthUnit')}
				/>
				<UpperTableElement
					headline={'NextGeneralInspection'}
					value1={formatDate(
						contentModel.str('generalinspection', '-')
					)}
				/>
				<UpperTableElement
					headline={'WarrantyType'}
					value1={
						contentModel.bool('warranty')
							? i18next.t('WarrantyTrue')
							: '-'
					}
				/>
				<UpperTableElement
					headline={'PreviousOwners'}
					value1={contentModel.str('pown', '-')}
				/>
				<UpperTableElement
					headline={'ModelYear'}
					value1={contentModel.float('modelyear')}
				/>
			</>
		);
	}

	// Helper function to format dates or handle invalid dates
	function formatDate(dateString: string | number | Date) {
		const date = new Date(dateString);
		return date.toString() === 'Invalid Date'
			? '-'
			: date.toLocaleDateString(i18next.language, {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
			  });
	}

	// Additional helper for battery and performance details
	function renderBatteryAndPerformanceDetails(
		engine: SSPContentFieldAccessor
	) {
		return engine.has('battery_charging_ac_100') ? (
			<UpperTableElement
				headline={'OverviewBoxChargingTime'}
				value1={engine.str('battery_charging_ac_100', '-')}
				unit1={i18next.t('ChargingTimeUnit')}
			/>
		) : engine.has('acceleration') ? (
			<UpperTableElementNumber
				headline={'OverviewBoxAcceleration'}
				value={engine.float('acceleration', NaN)}
				unit={'AccelerationUnit'}
			/>
		) : (
			<UpperTableElement
				headline={'OverviewBoxFuelType'}
				value1={engine.str('fuel2', '-')}
			/>
		);
	}

	return (
		<>
			<div className='upper-table overview'>
				{/* Power display section based on engine type */}
				<div
					className={`${
						engine.str('engine_type') === 'HybridPetrol'
							? 'hybrid '
							: ''
					}power`}
				>
					{!(
						engine.str('max_output_kw') &&
						engine.str('max_output_ps')
					) ? (
						<UpperTableElement
							headline={'OverviewBoxPower'}
							value1={
								engine.str('engine_type') === 'Electric'
									? engine.str('fuel_power2', '-')
									: engine.str('fuel_power', '-')
							}
							unit1={i18next.t('FuelPowerUnit')}
							value2={
								engine.str('engine_type') === 'Electric'
									? engine.str('fuel_power2_PS')
									: engine.str('fuel_power_PS')
							}
							unit2={i18next.t('FuelPowerPSUnit')}
						/>
					) : (
						<UpperTableElement
							headline={'OverviewBoxSystemPower'}
							value1={engine.str('max_output_kw', '-')}
							unit1={i18next.t('FuelPowerUnit')}
							value2={engine.str('max_output_ps')}
							unit2={i18next.t('FuelPowerPSUnit')}
						/>
					)}
				</div>

				{/* Consumption details based on engine type and market */}
				<div
					className={`${
						engine.str('engine_type') === 'HybridPetrol'
							? 'hybrid consumption'
							: 'consumption'
					}`}
					onClick={() => addOverlay(store, OverlayEnum.Consumption)}
				>
					<UpperTableElementNedcWltp
						headline={'OverviewBoxConsumption'}
						value={
							setup.market.name === 'MEX'
								? 'ObservedCombinedCO2Emissions'
								: 'xxx_consumption_electric_combined'
						}
						unit={
							setup.market.name === 'MEX'
								? i18next.t('WltpMexicoEmissionUnit')
								: 'ElectricConsumptionUnit'
						}
						model={vehicleDataModel}
						engine={engine}
						add={moreData}
						disclaimer={1}
					/>
				</div>

				{/* Display CritAir or other engine-related data */}
				{setup.market.name === 'FRA' ? (
					<UpperTableElement headline={'CritAir'} svg={critAir} />
				) : (
					renderEngineSpecificDetails(
						engine,
						contentModel,
						vehicleDataModel
					)
				)}

				{/* Used car additional information toggle */}
				{usedCar && (
					<div className='plus-btn-container'>
						<button
							className='showMore'
							onClick={() => setShowMoreOpen(!showMoreOpen)}
						>
							<svg className='icon-48'>
								<use
									xlinkHref={
										showMoreOpen
											? '#cancel-circle'
											: '#add-circle'
									}
								/>
							</svg>
							<p>
								{i18next.t(
									showMoreOpen
										? 'ShowLess'
										: 'ShowMoreInformation'
								)}
							</p>
						</button>
						{showMoreOpen && renderUsedCarDetails(contentModel)}
					</div>
				)}
			</div>

			{allHaveAsset ? (
				highlightEquip.map((equipItem, index) => (
					<div
						className='teaser-box'
						key={`teaser-box-${index}`}
						onClick={() => addOverlay(store, equipItem)}
					>
						<TeaserImage
							equipItem={equipItem}
							iconVideo='#play-circle'
						/>
						<div className='teaser-box-right'>
							<p className='small category'>
								{(equipItem.asModel.target.equipcategory &&
									equipItem.asModel.target.equipcategory[
										i18next.language
									]) ||
									equipItem.asModel.str('equipcategory')}
							</p>
							<div className={`truncate ${apk ? 'apk' : ''}`}>
								<h3>
									{(equipItem.asModel.target.layertitle &&
										equipItem.asModel.target.layertitle[
											i18next.language
										]) ||
										equipItem.asModel.str('layertitle')}
								</h3>
								<p
									className='long'
									dangerouslySetInnerHTML={checkHtml(
										(equipItem.asModel.target[
											'teasertext'
										] &&
											equipItem.asModel.target[
												'teasertext'
											][i18next.language]) ||
											equipItem.asModel.str('teasertext'),
										i18next.t('ClickMe')
									)}
								/>
							</div>

							<button>
								{i18next.t('LearnMore')}
								<svg className='icon-24'>
									<use xlinkHref='#chevron-right' />
								</svg>
							</button>
						</div>
					</div>
				))
			) : (
				<div className='highlight-table'>
					<div className='table-element headline'>
						<h4 className='bold'>
							{i18next.t('EquipmentHighlights')}
						</h4>
					</div>
					{highlightEquip.map((equipItem, index) => {
						const item = equipItem.asModel;
						const showIcon = showInfoIcon(equipItem);
						return (
							<div
								key={`table-element-${index}`}
								className={`table-element${
									showIcon ? ' with-end' : ''
								}`}
								onClick={() => {
									if (showIcon) {
										addOverlay(store, equipItem);
									}
								}}
							>
								<p className='short'>
									{(item.target.layertitle &&
										item.target.layertitle[
											i18next.language
										]) ||
										item.str('layertitle')}
								</p>
								{showIcon && (
									<div className='icon-24 info-icon'>
										<svg>
											<use xlinkHref='#system-info' />
										</svg>
									</div>
								)}
							</div>
						);
					})}
				</div>
			)}
			<div className='disclaimers pre-wrap'>
				{disclaimerTags.length > 0 && (
					<Disclaimers
						tagsUsed={disclaimerTags}
						complete={engineDisclaimers}
					/>
				)}
			</div>
		</>
	);
}
