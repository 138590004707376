import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import {
	addOverlay,
	getNumberString,
	OverlayEnum,
	SspProviderContext,
} from '../../did-utility';
import { CURRENCY_ISO } from '../../did-utility/src/constants';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { marked } from 'marked';
import { SET_OVERLAYS_ACTION } from '../../did-utility/src/SspProvider/store';
import { fetchFinances } from '../../did-utility/src/Functions/fetchFinances';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import clickLinks from '../../did-utility/src/Functions/clickLinks';
import i18next from 'i18next';
import { __MARKED_OPTIONS } from '../../did-utility/src/Components/Constans';
import { checkConnection } from '../../utils/connectionUtils';

let calcExpiresTimer: number | undefined;
export default function PriceInfo(props: { hidden: boolean }): JSX.Element {
	const store = useContext(SspProviderContext);
	const { i18n } = useTranslation();
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const calcFocus = contentModel.str('calculation_focus') === 'true';
	const useLiveCalculation = contentModel.bool('uselivecalculation');
	const calcLocalStorage = localStorage.getItem('Calc');
	const [calc, setCalc] = useState<any>(null);
	const [online, setOnline] = useState<boolean>(navigator.onLine);
	const price =
		contentModel.float('price', 0) ||
		contentModel.float('price_api', 0) ||
		undefined;
	const bevPricesheet =
		localStorage.getItem('Pricesheet') === 'BEVPricesheet';

	const duration = contentModel
		.ref('auto_financing_calculation')
		.asModel.str('overview_rate_title');
	const rate = contentModel
		.ref('auto_financing_calculation')
		.asModel.float('overview_rate');
	const rateSuffix = contentModel
		.ref('auto_financing_calculation')
		.asModel.str('overview_rate_suffix');
	const showCalc = contentModel.str('calc_product');

	let calcDisclaimer: string | null = null;
	let calcRate: string | null = null;
	let calcLabel: string | null = null;
	let calcDescription: string | null = null;
	let validFor = 0;
	let checkConnectionTimer: number | undefined;

	useEffect(() => {
		if (useLiveCalculation) {
			checkConnection(
				navigator.onLine,
				setOnline,
				checkConnectionTimer,
				timer => (checkConnectionTimer = timer)
			);
		}
	}, [checkConnection]);

	useEffect(() => {
		if (navigator.onLine && calc === null && price && !props.hidden) {
			fetchFinances(contentModel, 'DID.AUDI.NEW.DE', null, setCalc, null);
		} else if (
			!navigator.onLine &&
			store.state.overlays.includes(OverlayEnum.Leasing)
		) {
			store.dispatch({
				type: SET_OVERLAYS_ACTION,
				value: store.state.overlays.filter(
					overlay => overlay !== OverlayEnum.Leasing
				),
			});
		}
	}, [online, props.hidden, calc]);

	if (useLiveCalculation) {
		if (online && calc) {
			const summary =
				calc.result?.summaries.find((s: any) => s.type === 'Full') ||
				calc.result?.summaries[0];
			const financingGroup = summary.detailGroups.find(
				(g: any) => g.id === 'Financing'
			);
			calcDescription = financingGroup?.description || null;
			const detailRate = financingGroup?.detail?.find(
				(d: any) => d.id === 'Rate'
			);
			calcDisclaimer =
				calc.result?.disclaimers?.calculationDisclaimer || null;
			if (detailRate?.value) {
				const rateValueString =
					detailRate.value.indexOf('.') <
					detailRate.value.indexOf(',')
						? detailRate.value.replace('.', '').replace(',', '.')
						: detailRate.value.replace(',', '');
				calcRate = getNumberString(
					parseFloat(rateValueString || NaN),
					i18n.language,
					detailRate?.units?.value,
					2
				);
			}
			calcLabel = detailRate?.label || null;
			localStorage.setItem(
				'Calc',
				JSON.stringify({
					calcDisclaimer: calcDisclaimer,
					calcRate: calcRate,
					calcLabel: calcLabel,
					calcDescription: calcDescription,
					expires: Date.now() + 24 * 60 * 60 * 1000,
				})
			);
			validFor = calc.expires - Date.now();
		} else if (calcLocalStorage) {
			const local = JSON.parse(calcLocalStorage);
			validFor = parseInt(local.expires) - Date.now();
			if (validFor > 0) {
				calcDisclaimer = local.calcDisclaimer;
				calcRate = local.calcRate;
				calcLabel = local.calcLabel;
				calcDescription = local.calcDescription;
			}
		}

		if (!calcExpiresTimer && validFor) {
			calcExpiresTimer = window.setTimeout(() => {
				console.warn('Calculation expired!');
				localStorage.removeItem('Calc');
				setCalc(null);
			}, validFor);
		}
	}

	function renderPriceInfo() {
		return (
			<p className='small'>
				{i18next.t(contentModel.str('price_term'))}{' '}
				{getNumberString(price || NaN, i18n.language, CURRENCY_ISO, 2)}
				{contentModel.str('price_note') &&
					contentModel.str('price_note') !== 'none' &&
					' ' + i18next.t(contentModel.str('price_note'))}
			</p>
		);
	}

	return (
		<div className={`price-info${props.hidden ? ' hidden' : ''}`}>
			<div className='container'>
				<div className='price-wrapper'>
					<div className='left'>
						{price && (
							<>
								{showCalc !== 'NONE' &&
								calcFocus &&
								calcRate ? (
									<>
										<p className='detail-rate'>
											{calcLabel || ''}
										</p>
										<h3>
											{calcRate}{' '}
											<span className='small'>
												{calcDescription}
											</span>
										</h3>
										<p className='small'>
											{i18next.t(
												contentModel.str('price_term')
											)}{' '}
											{getNumberString(
												price || NaN,
												i18n.language,
												CURRENCY_ISO,
												2
											)}
											{contentModel.str('price_note') &&
												contentModel.str(
													'price_note'
												) !== 'none' &&
												' ' +
													i18next.t(
														contentModel.str(
															'price_note'
														)
													)}
										</p>
									</>
								) : showCalc !== 'NONE' && calcFocus && rate ? (
									<>
										<p className='detail-rate'>
											{duration}
										</p>
										<h3>
											{getNumberString(
												rate || NaN,
												i18next.language,
												CURRENCY_ISO,
												2
											)}{' '}
											<span className='small'>
												{rateSuffix}
											</span>
										</h3>
										<p className='small'>
											{i18next.t(
												contentModel.str('price_term')
											)}{' '}
											{getNumberString(
												price || NaN,
												i18n.language,
												CURRENCY_ISO,
												2
											)}
											{contentModel.str('price_note') &&
												contentModel.str(
													'price_note'
												) !== 'none' &&
												' ' +
													i18next.t(
														contentModel.str(
															'price_note'
														)
													)}
										</p>
									</>
								) : (
									<>
										<p className='price-term'>
											{contentModel.has('price_term')
												? i18next.t(
														contentModel.str(
															'price_term'
														)
													)
												: bevPricesheet
													? i18next.t(
															'CalculationPriceList'
														)
													: ''}
										</p>
										<h3>
											{getNumberString(
												price || NaN,
												i18n.language,
												CURRENCY_ISO,
												2
											)}{' '}
											<span className='small'>
												{contentModel.has(
													'price_note'
												) &&
													contentModel.str(
														'price_note'
													) !== 'none' &&
													i18next.t(
														contentModel.str(
															'price_note'
														)
													)}
											</span>
										</h3>
										{showCalc !== 'NONE' && calcRate ? (
											<p>
												{i18next.t(
													'CalculationOrMonthlyRate'
												) +
													' ' +
													calcRate}
											</p>
										) : showCalc !== 'NONE' &&
										  contentModel.has(
												'auto_financing_calculation'
										  ) &&
										  rate ? (
											<p>
												{i18next.t(
													'CalculationOrMonthlyRate'
												) +
													' ' +
													getNumberString(
														rate || NaN,
														i18next.language,
														CURRENCY_ISO,
														2
													)}{' '}
											</p>
										) : (
											''
										)}
									</>
								)}
								{showCalc !== 'NONE' &&
								useLiveCalculation &&
								calcDisclaimer ? (
									<p
										className='small calc-disclaimer'
										onClick={e => clickLinks(e, store)}
										dangerouslySetInnerHTML={checkHtml(
											marked.parse(
												calcDisclaimer,
												__MARKED_OPTIONS
											) as string,
											i18next.t('ClickMe')
										)}
									/>
								) : showCalc !== 'NONE' &&
								  contentModel.has(
										'auto_financing_calculation'
								  ) ? (
									<p
										className='small calc-disclaimer'
										onClick={e => clickLinks(e, store)}
									>
										{(contentModel.ref(
											'auto_financing_calculation'
										).asModel.target[
											'overview_disclaimer'
										] &&
											contentModel.ref(
												'auto_financing_calculation'
											).asModel.target[
												'overview_disclaimer'
											][i18next.language]) ||
											contentModel
												.ref(
													'auto_financing_calculation'
												)
												.asModel.str(
													'overview_disclaimer'
												)}
									</p>
								) : (
									''
								)}
							</>
						)}

						{((showCalc !== 'NONE' &&
							useLiveCalculation &&
							calc &&
							online) ||
							(showCalc !== 'NONE' &&
								contentModel.has(
									'auto_financing_calculation'
								))) && (
							<button
								className='small'
								onClick={() => {
									addOverlay(store, OverlayEnum.Leasing);
								}}
							>
								{i18next.t(
									useLiveCalculation && calc && online
										? 'CalculationOptionsRateChange'
										: 'DynamicFinancingTitle'
								)}
							</button>
						)}
						{bevPricesheet && (
							<div className='contract-partner'>
								<b>{i18next.t('BevContractPartner')}</b>
								<p>{contentModel.str('ContractPartner')}</p>
							</div>
						)}
						{contentModel.has('price_list') &&
							contentModel.bool('show_price_list', false) && (
								<>
									<p className='small bold'>
										{i18next.t('CalculationPriceList') +
											' ' +
											getNumberString(
												contentModel.float(
													'price_list',
													NaN
												),
												i18n.language,
												CURRENCY_ISO,
												2
											)}
									</p>
									<p className='small price-list-disclaimer'>
										<span className='small'>
											{i18next.t(
												'CalculationPriceListDisclaimer'
											)}
										</span>
									</p>
								</>
							)}
					</div>
				</div>
				{contentModel.has('price_freetext') && (
					<p
						className={`small freetext ${
							!price &&
							!useLiveCalculation &&
							!contentModel.has('price_list')
								? 'no-border'
								: ''
						} `}
						onClick={e => clickLinks(e, store)}
						dangerouslySetInnerHTML={checkHtml(
							marked.parse(
								contentModel.target.price_freetext[
									i18next.language
								].replaceAll('\n', '<br/>'),
								__MARKED_OPTIONS
							) as string,
							i18next.t('ClickMe')
						)}
					/>
				)}
			</div>
		</div>
	);
}
