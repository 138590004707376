export function checkConnection(
	prev: boolean,
	setOnline: (online: boolean) => void,
	timer: number | undefined,
	setTimer: (timer: number | undefined) => void,
	interval = 10000 // default to 10 seconds
) {
	let next = prev;
	if (navigator.onLine && !prev) {
		next = true;
		setOnline(true);
	} else if (!navigator.onLine && prev) {
		next = false;
		setOnline(false);
	}
	if (timer) window.clearTimeout(timer);
	setTimer(
		window.setTimeout(
			() => checkConnection(next, setOnline, timer, setTimer, interval),
			interval
		)
	);
}
