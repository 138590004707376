import { useEffect, useState } from 'react';
import { getSetup } from './getSetup';

const setup = getSetup();

const useServiceWorker = (
    password: string,
    brand: string,
    cacheUrlsForBrand: string[]
) => {
    const [swRegistration, setSwRegistration] =
        useState<ServiceWorkerRegistration | null>(null);
    const [updateTrigger, setUpdateTrigger] = useState(true);

    window.addEventListener('set-storage-key', () => setUpdateTrigger(false));
    window.addEventListener('remove-storage-key', () => setUpdateTrigger(true));

    useEffect(() => {
        if (!('serviceWorker' in navigator)) return;

        const registerSW = async () => {
            try {
                const registrations =
                    await navigator.serviceWorker.getRegistrations();
                let swReg: ServiceWorkerRegistration | null =
                    registrations[0] || null;

                if (!swReg) {
                    swReg = await navigator.serviceWorker.register(
                        '../serviceWorker.js',
                        { scope: '/' }
                    );
                    console.log('Service Worker registered:', swReg);
                } else {
                    console.log('Service Worker found:', swReg);
                }

                setSwRegistration(swReg);

                // Listen for messages from the Service Worker
                navigator.serviceWorker.addEventListener('message', event => {
                    console.log('SW Message: sw-cache-ready', event.data);
                    localStorage.setItem(
                        `sw-cache-ready${
                            event.data.brand ? `-${event.data.brand}` : ''
                        }`,
                        event.data.msg
                    );
                });

                // Send messages to the Service Worker
                const serviceWorker =
                    swReg.installing || swReg.waiting || swReg.active;
                if (serviceWorker && serviceWorker.state !== 'redundant') {
                    serviceWorker.postMessage({
                        type: 'CACHE_URLS',
                        cacheEnding: `-${brand}`,
                        brand,
                        payload: Array.from(
                            new Set([
                                ...performance
                                    .getEntriesByType('resource')
                                    .map(r =>
                                        (
                                            r as PerformanceResourceTiming
                                        ).name.replace(
                                            window.location.origin,
                                            ''
                                        )
                                    )
                                    .filter(name =>
                                        name.includes(window.location.origin)
                                    ),
                                ...cacheUrlsForBrand,
                            ])
                        ),
                    });

                    serviceWorker.postMessage({
                        type: 'AUTH',
                        payload: window.btoa(`ssp:${password}`),
                    });
                }
            } catch (err) {
                console.error('Service Worker registration failed:', err);
            }
        };

        registerSW();
    }, [password, brand, cacheUrlsForBrand]);

    // Periodically check for Service Worker updates
    useEffect(() => {
        if (!swRegistration) {
            return;
        }

        if (!updateTrigger) {
            return;
        }

        const intervalId = setInterval(async () => {
            try {
                await swRegistration.update();

                const latestIndexJs = await fetchLatestJs();
                const cachedIndexJs = localStorage.getItem('index-js');

                if (latestIndexJs !== cachedIndexJs) {
                    localStorage.setItem('index-js', latestIndexJs);
                    window.location.reload();
                }
            } catch (err) {
                console.error('Service Worker update check failed:', err);
            }
        }, 60 * 1000);

        return () => {
            console.log('Cleaning up interval');
            clearInterval(intervalId);
        };
    }, [swRegistration, updateTrigger]);

    return swRegistration;
};

export default useServiceWorker;

/**
 * Fetch the latest index.js filename from index.html.
 */
async function fetchLatestJs(): Promise<string> {
    try {
        const response = await fetch(
            window.location.origin + '/' + setup.brand + '/index.html',
            { cache: 'no-store' }
        );
        if (!response.ok) return '';
        const html = await response.text();
        const match = html.match(/index-[a-zA-Z0-9]+\.js/);

        return match ? match[0] : '';
    } catch (err) {
        console.error('Failed to fetch latest index.js:', err);
        return '';
    }
}
