import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { NavEnum, OverlayEnum } from '../enums';
import { SspProviderState } from '../SspProvider/interfaces';
import {
    ACTION,
    SET_NAV_TAB_ACTION,
    SET_OVERLAYS_ACTION,
} from '../SspProvider/store';
import dispatchLogEvent, { flushEventQueue, UNSENT_EVENTS_KEY } from './index';
import i18next from 'i18next';

export const STORAGE_KEY = 'sspTrackingSessionId';

export function makeGUID(): string {
    return [makeId(8), makeId(4), makeId(4), makeId(4), makeId(10)].join('-');
}

function makeId(length: number): string {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
}

export async function fetchWithTimeout(
    url: string,
    data: any,
    apiToken: string,
    attempt = 1
) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);

    try {
        const response = await fetch(url, {
            method: 'ADD',
            body: JSON.stringify(data),
            signal: controller.signal,
            headers: {
                'Service-Worker': 'false',
                'X-B12-Stats-Tracking-Token': apiToken,
                'Content-Type': 'application/json',
            },
        });

        clearTimeout(id);

        // try to resend data if there is a 409 error
        if (response.status === 409 && attempt < 3) {
            const currentTimestamp = new Date(data.Timestamp).getTime();
            data.Timestamp = new Date(currentTimestamp + 1).toISOString();

            return await fetchWithTimeout(url, data, apiToken, attempt + 1);
        } else if (response.ok) {
            console.log('Data sent successfully');
            return response;
        } else {
            console.error(`Error: Received status code ${response.status}`);
            return response;
        }
    } catch (error) {
        clearTimeout(id);
        console.error('Fetch error:', error);
        throw error;
    }
}

export function saveUnsentEvents(events: any[]) {
    localStorage.setItem(UNSENT_EVENTS_KEY, JSON.stringify(events));
}

export function clearUnsentEvents() {
    localStorage.removeItem(UNSENT_EVENTS_KEY);
}

//need overlayNav & overlayNum in logColorChange but get info out of logStateChange
let overlayNav = '';
let overlayNum = 0;

// use endTime for session end and leave it undefined for session start
export async function logSession(endTime?: number) {
    if (endTime && sessionStorage.getItem(STORAGE_KEY)) {
        dispatchLogEvent({
            ActionName: 'Session End',
            ElementType: 'Session',
            Timestamp: endTime,
        });
        await flushEventQueue(); // Send all events on session end
        sessionStorage.removeItem(STORAGE_KEY);
        window.dispatchEvent(new CustomEvent('remove-storage-key'));
    } else {
        const guid = makeGUID();
        sessionStorage.setItem(STORAGE_KEY, guid);
        window.dispatchEvent(new CustomEvent('set-storage-key'));
        dispatchLogEvent({
            SessionId: guid,
            ActionName: 'Session Start',
            ElementType: 'Tab',
            ElementId: 'Overview',
            ElementName:
                sessionStorage.getItem('pricesheetTitle') ||
                '' + ' | ' + sessionStorage.getItem('vin') ||
                '',
            ElementHierarchy: 'Overview',
            ElementContentType: sessionStorage.getItem('car') || '',
            ElementContentUid: sessionStorage.getItem('pricesheetUID') || '',
        });
    }
}

function getElementName(
    overlay: SSPContentVO | OverlayEnum,
    state?: SspProviderState
): string | undefined {
    if (overlay) {
        // is OverlayEnum
        if (Object.values(OverlayEnum).includes(overlay as number)) {
            let name =
                {
                    [OverlayEnum.QRCode]: i18next.t('ContentToGo'),
                    [OverlayEnum.Tire]: i18next.t('Tyrelabel'),
                    [OverlayEnum.Consumption]: i18next.t(
                        'ConsumptionAndEmissions'
                    ),
                    [OverlayEnum.Menu]: i18next.t('GeneralModelInformation'),
                }[overlay as number] ||
                i18next.t(OverlayEnum[overlay as number]);

            if (overlay === OverlayEnum.QRCode && state && state.content) {
                const has = ['onlineCode', 'weblink', 'weblink_dga'].filter(
                    key => state.content?.asModel?.has(key)
                );
                name += ` (${has.join(', ')})`;
            }
            return name;
        }

        // is SSPContentVO
        if ((overlay as SSPContentVO).name) {
            let name = (overlay as SSPContentVO).asModel.target.layertitle[
                i18next.language
            ];
            if ((overlay as SSPContentVO).asModel.has('number')) {
                name = `${(overlay as SSPContentVO).asModel.str(
                    'number'
                )} | ${name}`;
            }
            return name;
        }
    }
    return undefined;
}

export function logStateChange(state: SspProviderState, action: ACTION) {
    if (
        action.type === SET_OVERLAYS_ACTION &&
        state.overlays.length !== action.value.length &&
        !action.noLog
    ) {
        const overlay =
            state.overlays.length < action.value.length
                ? action.value[action.value.length - 1]
                : state.overlays[state.overlays.length - 1];

        if (overlay) {
            let elementHierarchy = [
                NavEnum[state.navTab],
                ...action.value.map(e => getElementName(e)),
            ].join(' | ');
            let parts: string[] = [];
            if ((overlay as SSPContentVO).name) {
                parts = elementHierarchy.split(' | ');
                elementHierarchy =
                    parts.slice(0, 2).join(' | ') + parts.slice(3).join(' | ');
            }
            if (state.overlays.length > action.value.length) {
                let inputElementHierarchy =
                    elementHierarchy + ' | ' + getElementName(overlay, state);
                let parts = inputElementHierarchy.split(' | ');
                elementHierarchy =
                    parts.slice(0, 2).join(' | ') + parts.slice(3).join(' | ');
            }

            overlayNav = NavEnum[state.navTab];
            overlayNum = overlay as number;

            dispatchLogEvent({
                ActionName:
                    state.overlays.length < action.value.length
                        ? 'Open'
                        : 'Close',
                ElementName: getElementName(overlay, state),
                ElementId: (overlay as number) === 8 ? 'ModelInfo' : 'Overlay',
                ElementType:
                    (overlay as number) === 8
                        ? 'ModelInfo'
                        : (overlay as number) === 3
                        ? 'CompleteWheelsOverlay'
                        : 'EquipmentOverlay',
                ElementHierarchy: elementHierarchy,
                ElementContentType:
                    (overlay as number) === 8
                        ? 'Salesgroup'
                        : (overlay as number) === 2 || (overlay as number) === 3
                        ? 'Equipments'
                        : 'Equipment',
                ElementContentUid:
                    (overlay as number) === 8
                        ? sessionStorage.getItem('menuUID') || ''
                        : (overlay as SSPContentVO).uid,
            });
        }
    }

    if (
        action.type === SET_NAV_TAB_ACTION &&
        state.navTab !== action.value &&
        !action.noLog
    ) {
        dispatchLogEvent({
            ActionName: 'Click',
            ElementType: 'Tab',
            ElementName: i18next.t(NavEnum[action.value]),
            ElementHierarchy: 'Overview' + ' | ' + NavEnum[state.navTab],
            ElementId: NavEnum[action.value],
            ElementContentType: 'Equipments',
            ElementContentUid: sessionStorage.getItem('pricesheetUID') || '',
        });
    }
}

export function logVideoChange(video: SSPContentVO, actionName: string) {
    dispatchLogEvent({
        ActionName: actionName,
        ElementName: video.name,
        ElementType: 'AssetVideo',
        ElementId: 'Video',
        ElementContentType: 'AssetVideo',
        ElementContentUid: video.uid,
    });
}

export function logSlideChange(asset: SSPContentVO, index: number) {
    if (asset?.name) {
        dispatchLogEvent({
            ActionName: 'Click',
            ElementName: asset.name,
            ElementType: asset.contentType || 'asset',
            ElementId: 'Slider',
            ElementContentType: asset.contentType || 'asset',
            ElementContentUid: asset.uid,
        });
    }
}

export function logColorChange(navElementIndex: number) {
    if (overlayNum === 2 && (navElementIndex === 0 || navElementIndex === 1)) {
        dispatchLogEvent({
            ActionName: 'Click',
            ElementName:
                navElementIndex === 0
                    ? i18next.t('ExtColors')
                    : i18next.t('IntColors'),
            ElementType: 'ColorsOverlay',
            ElementId: 'Overlay',
            ElementHierarchy:
                overlayNav +
                ' | ' +
                i18next.t('GeneralModelInformation') +
                ' | ' +
                (navElementIndex === 0
                    ? i18next.t('ExtColors')
                    : i18next.t('IntColors')),
            ElementContentType: 'Equipments',
        });
    }
}
