import './styles.scss';
import React, { useContext, useRef, useState } from 'react';
import i18next from 'i18next';
import { marked } from 'marked';
import { checkHtml } from '../../Functions/checkHTML';
import LanguageMap from '../LaungugeMap';
import BuildInfo from '../BuildInfo';
import { BackToSetup, SspProviderContext } from '../../../index';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { __MARKED_OPTIONS } from '../Constans';
import { getSetup } from '../../Functions/getSetup';

interface FooterProps {
	showLargeFooter: boolean;
	brand: 'vw' | 'audi' | 'bentley';
}

export default function Footer({ showLargeFooter, brand }: FooterProps) {
	const setup = getSetup();
	const [languageOpen, setLanguageOpen] = useState<boolean>(false);
	const [showXlFooter, setShowXlFooter] = useState<boolean>(false);
	const store = useContext(SspProviderContext);
	const footerRef = useRef<HTMLDivElement>(null);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const languages = Object.keys(i18next.options.resources as Object);
	const individualFooter =
		(contentModel.target.individual_footer &&
			contentModel.target.individual_footer[i18next.language]) ||
		contentModel.str('individual_footer');

	const footerText = checkHtml(
		marked.parse(individualFooter, __MARKED_OPTIONS) as string
	);

	return (
		<>
			<div ref={footerRef} className={`footer footer-${brand}`}>
				<div className='footer-content'>
					<div className='footer-buttons'>
						{brand === 'vw' &&
							!showLargeFooter &&
							footerText.__html && (
								<button
									className='icon-button'
									id='footer-info-btn'
									onClick={() => {
										setShowXlFooter(prev => !prev);
									}}
								>
									<svg
										className={`${setup.brand === 'bentley' ? 'icon-16' : 'icon-24'}`}
									>
										<use
											xlinkHref={
												showXlFooter
													? '#cancel'
													: '#system-info'
											}
										/>
									</svg>
								</button>
							)}
						<button
							id='footer-language-btn'
							className='set-language-open'
							onClick={() => setLanguageOpen(true)}
							style={
								languages.length > 1
									? {}
									: { width: 0, padding: 0 }
							}
						>
							{languages.length > 1 && (
								<>
									<svg
										className={`${setup.brand === 'bentley' ? 'icon-16' : 'icon-24'}`}
									>
										<use xlinkHref={'#language'} />
									</svg>
									<p
										className={`${
											languageOpen ? 'active ' : ''
										}language-letters`}
									>
										{setup.brand === 'bentley'
											? i18next
													.t('ThisLanguage', {
														defaultValue:
															i18next.language,
													})
													.toUpperCase()
											: i18next.t('ThisLanguage', {
													defaultValue:
														i18next.language,
												})}
									</p>
								</>
							)}
						</button>
					</div>

					{languages.length > 1 && (
						<div
							className={`language-popup ${
								languageOpen ? 'open' : ''
							}`}
						>
							<button onClick={() => setLanguageOpen(false)}>
								<svg
									className={`icon-${
										brand === 'bentley' ? '48 lang' : '24'
									}`}
								>
									<use xlinkHref='#cancel' />
								</svg>
							</button>
							<div className='languages'>
								<LanguageMap
									languages={languages}
									store={store}
									setLanguageOpen={setLanguageOpen}
								/>
							</div>
						</div>
					)}

					<p className='small'>
						{contentModel.str('vin') && (
							<>
								{i18next.t('SerialNumberAbbreviation') +
									': ' +
									contentModel.str('vin')}
							</>
						)}
						{contentModel.has('vin') &&
						contentModel.has('commissionnr')
							? ' | '
							: ''}
						{contentModel.str('commissionnr') && (
							<>
								{i18next.t('JobNumberAbbreviation') +
									': ' +
									contentModel.str('commissionnr')}
							</>
						)}
						<BuildInfo local={i18next.language} />
					</p>
				</div>
				{brand === 'vw' && (showLargeFooter || showXlFooter) && (
					<div className='scrollable-footer'>
						<p
							className={`individual-footer ${
								showXlFooter ? 'maxi' : 'mini'
							}`}
							dangerouslySetInnerHTML={footerText}
						/>
					</div>
				)}
			</div>

			<BackToSetup
				brand={brand}
				langBtnId={'footer-language-btn'}
				infoBtnId={'footer-info-btn'}
			/>
		</>
	);
}
