import React from 'react';
import './styles.scss';
import { SliderProps } from './interfaces';
import Carousel from '../Carousel';
import { getSetup } from '../../Functions/getSetup';

export default function Slider(props: SliderProps) {
	const setup = getSetup();
	const children = props.children as JSX.Element[];
	const clickHandler = (add: number) => {
		props.setCurrentIndex(props.currentIndex + add);
	};

	if (children.length <= 1) {
		return <div className='slider-root'>{children}</div>;
	}

	return (
		<div
			className={`slider-root ${setup.brand === 'bentley' ? 'bentley' : ''}`}
		>
			<Carousel
				currentIndex={props.currentIndex}
				setCurrentIndex={props.setCurrentIndex}
			>
				{children}
			</Carousel>
			<button
				className='left'
				onClick={() => clickHandler(-1)}
				disabled={props.currentIndex === 0}
			>
				{props.btnContent}
			</button>
			<button
				className='right'
				onClick={() => clickHandler(1)}
				disabled={props.currentIndex === children.length - 1}
			>
				{props.btnContent}
			</button>
			{children.length > 1 && (
				<div className='indicators'>
					{Array.from(Array(children.length).keys()).map(i => (
						<div
							className={`indicator${
								i === props.currentIndex
									? ' active-indicator'
									: ''
							}`}
						/>
					))}
				</div>
			)}
		</div>
	);
}
